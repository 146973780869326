<template><v-app>

	<v-app-bar app>
		<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
		<v-toolbar-title>Reservas 
			<span v-if="viewSettings.equipmentName" class="mx-2">
			> {{ viewSettings.equipmentName }}
			</span>
		</v-toolbar-title>
	</v-app-bar>
	<v-navigation-drawer v-model="drawer"
						fixed
						temporary
	>
		<v-btn class="py-5 mb-1 mx-3 bar-button"
				tile
				@click="showAll(); drawer=false;">
			<v-icon large>mdi-sigma</v-icon>
			<span>Todos</span>
		</v-btn>
		<v-btn v-for="equipment in equipments" 
				tile
				:key="equipment.id" 
				:class="['py-5', 'mb-1', 'mx-3', 'bar-button', 'filter-' + (equipment.color_scheme)]"
				@click="showOnly (equipment); drawer=false;">
			<v-icon large>{{equipment.icon}}</v-icon>
			<span>{{ equipment.name }}</span>
		</v-btn>
	</v-navigation-drawer>

	<v-main><span v-touch:swipe="swipeHandler">
		<vue-cal ref="vuecal"
				:disable-views="['years', 'year']" 
				small
				class="vuecal--green-theme vuecal--full-height-delete" 
				style="height: 1250px" 
				locale="es"
				:time-from="7*60"
				:time-to="19*60"
				:time-step="30"
				:events="events"
				@cell-click="createDialog"
				@event-delete="deleteEvent"
				@event-duration-change="updateEvent"
				:min-event-width="50"
				:snap-to-time="30"
				@ready="fetchEvents"
				@view-change="fetchEvents"
				:time-cell-height="25"
				:editable-events="{ title: false, drag: false, resize: false, delete: true, create: false }"
				>
			<template v-slot:time-cell="{ hours, minutes }">
				<div :class="{ 'vuecal__time-cell-line': true, hours }">
					<strong v-if="!minutes" style="font-size: 15px">{{ hours }}</strong>
				</div>
			</template>
			<template v-slot:event="{ event }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ event.title }} 
						</span>
						</template>
					<span>{{ event.start.formatTime("hh:mm") }} - 
						{{ event.end.formatTime("hh:mm") }}</span>
				</v-tooltip>
			</template>
		</vue-cal>

		<v-dialog v-model="showNewEvent"
				:persistent="true"
				max-width="500"
				@keydown.esc="cancelEventCreation();"
				>
			<v-card><form @submit.prevent>
				<v-card-title v-if="newEvent.date">
					Reserva de equipo para {{ newEvent.date.toLocaleDateString('es-AR') }} a las {{ newEvent.date.toLocaleTimeString('es-AR') }}
				</v-card-title>
				<v-card-text>
					<v-row><v-col><v-select label="Equipo"
											:items="equipments"
											name="equipment"
											item-text="name"
											append-icon="mdi-bulldozer"
											return-object
											v-validate="'required'"
											v-model="newEvent.equipment">
					</v-select>
					<small>{{ errors.first ('equipment') }}</small>
					</v-col></v-row>
					<v-row><v-col>
							<v-text-field v-model="newEvent.name"
											v-validate="'required'"
											name="name"
											placeholder="Quien reserva?"
											:class="{'is-invalid': errors.has('newEvent.name') && submitted}"></v-text-field>
							<small>{{ errors.first ('name') }}</small>
							
					</v-col></v-row>
					<v-row><v-col><v-btn-toggle
											v-model="newEvent.duration"
											mandatory>
						<v-btn value="30">0:30</v-btn>
						<v-btn value="60">1:00</v-btn>
						<v-btn value="90">1:30</v-btn>
						<v-btn value="120">2:00</v-btn>
						<v-btn value="180">3:00</v-btn>
						<v-btn value="240">4:00</v-btn>
					</v-btn-toggle></v-col></v-row>
					<v-row><v-layout>
						<v-btn @click="cancelEventCreation()">Cancelar</v-btn>
						<v-btn @click="createEvent()">Reservar</v-btn>
					</v-layout></v-row>
				</v-card-text>
			</form></v-card>
		</v-dialog> 

		<v-snackbar v-model="snack.show"
					top
					min-height="100">
			{{ snack.text }}
			<template v-slot:action="{ attrs }">
				<v-btn
					:color="snack.color"
					text
					v-bind="attrs"
					@click="snack.show = false"
				>
				X
				</v-btn>
			</template>
		</v-snackbar>
	</span></v-main>
</v-app></template>

<style>
	.bar-button { width: 90%; }
	.vuecal__event.flujo0, .filter-flujo0 { background-color: rgba(253, 156, 66, 0.9)!important; border: 1px solid rgb(233, 136, 46); color: #fff; }
	.vuecal__event.flujo1, .filter-flujo1 { background-color: rgba(255, 102, 102, 0.9)!important; border: 1px solid rgb(235, 82, 82); color: #fff; }
	.vuecal__event.micro, .filter-micro { background-color: rgba(255, 185, 185, 0.8)!important; border: 1px solid rgb(230, 55, 55, 0.3); color: #fff; }
	.vuecal__event.micro2, .filter-micro2 { background-color: rgba(185, 219, 255, 0.8)!important; border: 1px solid rgba(55, 173, 230, 0.3); color: #fff; }
	.vuecal__event.lupa, .filter-lupa { background-color: rgba(206, 205, 104, 0.8)!important; border: 1px solid rgb(184, 230, 55, 0.3); color: #fff; }
	.vuecal__event.camara, .filter-camara { background-color: rgba(197, 255, 185, 0.8)!important; border: 1px solid rgb(197, 255, 185, 0.3); color: #fff; }
	.vuecal__event.fotodyne, .filter-fotodyne { background-color: rgba(164, 230, 210, 0.9)!important; border: 1px solid #90d2be; color: #fff; }
	.vuecal__event.sum, .filter-sum { background-color: rgba(100,200,255,0.8)!important; border: 1px solid #50b4eb; color: #fff; }
	.vuecal__event.espectro, .filter-espectro { background-color: rgba(230,164,229,0.9)!important; border: 1px solid #d290b8; color: #fff; }
	.vuecal__event.background {
		background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px); color: #999; display: flex; justify-content: center; align-items: center; }
	.vuecal__event.background .vuecal__event-time {display: none; align-items: center;}
	.vuecal__no-event { display: none; }
	.vuecal__event {cursor: pointer;}
	.vuecal__event-title {
		font-size: 1.2em;
		font-weight: bold;
		margin: 4px 0 8px;
	}
</style>

<script>

import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/es.js';
import 'vue-cal/dist/vuecal.css';
import axios from 'axios';

const dict = {
	custom: {
		name: { required: 'Hay que poner el nombre'	},
		equipment: { required: 'Hay que elegir un equipo' }
	}
};

export default {
	components: { VueCal },
	data: () => ({
		snack: {
			text: '',
			show: false,
			color: ''
		},
		showNewEvent: false,
		newEvent: {
			date: undefined,
			name: '',
			duration: 0,
			equipment: '',
		},
		viewSettings: {
			startDate: undefined,
			endDate: undefined,
			view: undefined,
			equipment: 0,
			equipmentName: 'Todos',
			week: 0
		},
		events: [],
		equipments: [],
		drawer: null,
	}),
	created: function () {
		this.fetchEquiment ();
		this.$validator.localize('es', dict);
	},
	methods: {
		swipeHandler (evt) {
			console.log ('pepe');
			console.log (evt);
			if (evt === 'left') this.timeForward();
			if (evt === 'right') this.timeBack();
		},
		timeForward () {
			
			if (this.viewSettings.view == 'day') {
				this.viewSettings.startDate.addDays (1);
				this.viewSettings.startDate.addDays (1);
			}
			this.fetchEvents (this.viewSettings);
		},
		timeBack () {
			if (this.viewSettings.view == 'day') {
				this.viewSettings.startDate.addDays (-1);
				this.viewSettings.startDate.addDays (-1);
			}
			this.fetchEvents (this.viewSettings);

		},
		createDialog: function (cellDate) {
			var d = new Date()
			if (d <= cellDate) {
				this.$set (this.newEvent, 'date', this.roundDate (30, cellDate));
				this.showNewEvent = true;
			}
		},
		deleteEvent (event) {

			axios.delete ('/reservations/' + event.event_id)
				.then(response => {
					if (response.status == 200 && response.data.affectedRows == 1) {
						this.showSnack ("Turno cancelado", "green");
					} else {
						this.showSnack ("Algo salio mal", "red");
					}
				});
		},
		updateEvent ({ event, oldDate, originalEvent }) {
			axios.patch ('/reservations/' + event.event_id, { 
				params: { 
					startDate: event.start,
					endDate: event.end}
				}).then(response => {
					if (response.status == 200 && response.data.changedRows == 1) {
						this.showSnack ("Horario modificado", "green");
					} else {
						this.showSnack ("Algo salio mal", "red");
					}
				});
			
		},
		showOnly (equipment) {
			this.$set (this.viewSettings, 'equipment', equipment.id);
			this.$set (this.viewSettings, 'equipmentName', equipment.name);
			this.fetchEvents (this.viewSettings);
		},
		showAll () {
			this.$set (this.viewSettings, 'equipment', 0);
			this.fetchEvents (this.viewSettings);
		},
		fetchEquiment () {
			axios.get ('/equipment')
						.then(response => {
							//importante: saco background de los equipos que reserva el user
							this.equipments = response.data.filter (function (e) { return e.name != "background"; }); 
						});
		},
		fetchEvents (params) {
		
			Object.assign (this.viewSettings, params);

			axios.get ('/reservations/equipment/' + this.viewSettings.equipment, { params: params })
						.then(response => {
							this.$set (this, 'events', response.data);
						});
		},
		cancelEventCreation: function () {
			this.showNewEvent = false;
		},
		showSnack: function (text, color) {
			this.snack.text = text;
			this.snack.color = color;
			this.$set (this.snack, 'show', true);
		},
		createEvent: function () {
			
			this.$validator.validate().then (result => {
				
				this.submitted = true;
				if (!result) {
					return;
				}

				var endDate = new Date();
				endDate.setTime (this.newEvent.date.getTime() + (this.newEvent.duration * 60 * 1000));

				axios.post ('/reservations', { 
					params: {name: this.newEvent.name, 
							startDate: this.newEvent.date,
							endDate: endDate,
							equipment: this.newEvent.equipment.id}})

					.then(response => {

							if (response.status == 200 && response.data.affectedRows == 1) {

							this.$refs.vuecal.createEvent (this.newEvent.date, 
												this.newEvent.duration,
												{title: this.newEvent.name, 
												class: this.newEvent.equipment.color_scheme,
												event_id: response.data.insertId,
												equipment_id: this.newEvent.equipment.id});

							this.showSnack ("Equipo reservado", "green");
						} else if (response.data.affectedRows == 0) {

							this.showSnack ("No se reservo el equipo", "red");
						}
						

					});

				this.showNewEvent = false;
			});
		},
		roundDate: function (minutes, d=new Date()) {

			if (d.getMinutes () < minutes) {
				d.setMinutes (0, 0, 0);
			} else {
				d.setMinutes (minutes, 0, 0);
			}

			return d;
		}

	}

}
</script>