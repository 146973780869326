import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'
import CalendarIndex from './components/CalendarIndex.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@babel/polyfill'
import axios from 'axios'
import * as VeeValidate from 'vee-validate'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.use (VueRouter);
Vue.use (VeeValidate);

Vue.config.productionTip = false;

const routes = [
	{
		name: 'CalendarIndex',
		path: '/',
		component: CalendarIndex
	},
];

const router = new VueRouter({ mode: 'history', routes: routes })

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')