
<template>
	<div id="app" class="container is-max-desktop">
		<router-view />
	</div>
</template>
 
<script>
export default {
  name: "App",
};
</script>
 
